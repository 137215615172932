import { useEffect, useRef } from "react";
import Cookies from "js-cookie";

const INACTIVITY_TIME = 10 * 60 * 1000; // 10 minutos en milisegundos

const InactivityHandler = () => {
  const inactivityTimer = useRef(null);

  // Restablecer el temporizador de inactividad
  const resetInactivityTimer = () => {
    clearTimeout(inactivityTimer.current);
    inactivityTimer.current = setTimeout(() => {
      clearCookies();
      window.location.reload();
    }, INACTIVITY_TIME);
  };

  // Borrar las cookies
  const clearCookies = () => {
    Cookies.remove("cPass");
    Cookies.remove("user_id");
    Cookies.remove("access_token");
  };

  // Detectar actividad del usuario
  const handleUserActivity = () => {
    resetInactivityTimer();
  };

  // Detectar si la pestaña está inactiva o visible
  const handleVisibilityChange = () => {
    if (!document.hidden) {
      resetInactivityTimer(); // Al volver a la pestaña, reiniciamos el temporizador
    }
  };

  useEffect(() => {
    // Escuchar eventos de actividad del usuario
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);
    window.addEventListener("click", handleUserActivity);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Iniciar el temporizador cuando el componente se monta
    resetInactivityTimer();

    // Limpiar los listeners cuando el componente se desmonte
    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      window.removeEventListener("click", handleUserActivity);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      clearTimeout(inactivityTimer.current);
    };
  }, []);

  return null; // Este componente no necesita renderizar nada
};

export default InactivityHandler;
