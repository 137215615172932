// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useMaterialUIController } from "context";
import { fetchMetrics } from "context";

function Dashboard() {
  const [controller, dispatch] = useMaterialUIController();
  const [metricas, setMetricas] = useState({
    tradersActivosSA: "",
    tradersInactivosSA: "",
    tradersActivosTV: "",
    tradersInactivosTV: "",
    pagosManualesActivos: "",
    pagosManualesInactivos: "",
    pagosAutomaticos: "",
  });

  const { metrics } = controller;

  useEffect(() => {
    fetchMetrics(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (metrics && metrics.traders && metrics.pagosManuales && metrics.pagosAutomaticos) {
      const tradingEnVivo =
        metrics?.traders?.find((trader) => trader.name === "SALA PRE ANALISIS") || [];
      const salaDeAnalisis =
        metrics?.traders?.find((trader) => trader.name === "SALA POST ANALISIS") || [];
      setMetricas({
        tradersActivosSA: salaDeAnalisis?.activos || 0,
        tradersInactivosSA: salaDeAnalisis?.inactivos || 0,
        tradersActivosTV: tradingEnVivo?.activos || 0,
        tradersInactivosTV: tradingEnVivo?.inactivos || 0,
        pagosManualesActivos: metrics?.pagosManuales?.Procesados[0]?.total || 0,
        pagosManualesInactivos: metrics?.pagosManuales?.PorProcesar[0]?.total || 0,
        pagosManualesSuspendidos: metrics?.pagosManuales?.Supendidos[0]?.total || 0,
        pagosAutomaticos: metrics?.pagosAutomaticos?.Procesados[0]?.total || 0,
        pagosAutomaticosSuspendidos: metrics?.pagosAutomaticos?.Supendidos[0]?.total || 0,
      });
    }
  }, [metrics]);

  useEffect(() => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "f2f467cb-29d1-4f50-a3a1-8bc416a8f85e";

    const script = document.createElement("script");
    script.src = "https://client.crisp.chat/l.js";
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          {/**este es el puito cuadrito de booking*/}
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="card_membership "
                title="Sala Pre Análisis"
                count={
                  parseInt(metricas.tradersActivosTV) + parseInt(metricas.tradersInactivosTV) || 0
                }
                userActive={{
                  color: "green",
                  label: "Usuarios Activos",
                  amount: `${metricas.tradersActivosTV || 0}`,
                }}
                userInactive={{
                  color: "error",
                  label: "Usuarios Inactivos",
                  amount: `${metricas.tradersInactivosTV || 0}`,
                }}
              />
            </MDBox>
          </Grid>
          {/**este es el puito cuadrito de todays user*/}
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Sala Post Análisis"
                count={
                  parseInt(metricas.tradersActivosSA) + parseInt(metricas.tradersInactivosSA) || 0
                }
                userActive={{
                  color: "green",
                  label: "Usuarios Activos",
                  amount: `${metricas.tradersActivosSA || 0}`,
                }}
                userInactive={{
                  color: "error",
                  label: "Usuarios Inactivos",
                  amount: `${metricas.tradersInactivosSA || 0}`,
                }}
              />
            </MDBox>
          </Grid>
          {/**este es el puito cuadrito de Revenue*/}
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="person"
                title="Usuarios Totales"
                count={
                  parseInt(metricas.tradersActivosSA) +
                    parseInt(metricas.tradersInactivosSA) +
                    parseInt(metricas.tradersActivosTV) +
                    parseInt(metricas.tradersInactivosTV) || 0
                }
                userActive={{
                  color: "green",
                  label: "Total de Usuarios Activos",
                  amount: `${
                    parseInt(metricas.tradersActivosSA) + parseInt(metricas.tradersActivosTV) || 0
                  }`,
                }}
                userInactive={{
                  color: "error",
                  label: "Total de Usuarios Inactivos",
                  amount: `${
                    parseInt(metricas.tradersInactivosSA) + parseInt(metricas.tradersInactivosTV) ||
                    0
                  }`,
                }}
              />
            </MDBox>
          </Grid>
        </Grid>

        <MDBox py={3}>
          <Grid container spacing={3}>
            {/**este es el puito cuadrito de booking*/}
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="payments"
                  title="Pagos Manuales"
                  count={
                    parseInt(metricas.pagosManualesActivos) +
                      parseInt(metricas.pagosManualesInactivos) +
                      parseInt(metricas.pagosManualesSuspendidos) || 0
                  }
                  userActive={{
                    color: "green",
                    label: "Procesados",
                    amount: `${metricas.pagosManualesActivos || 0}`,
                  }}
                  userInactive={{
                    color: "error",
                    label: "Por Procesar",
                    amount: `${metricas.pagosManualesInactivos || 0}`,
                  }}
                  suspendidos={{
                    color: "error",
                    label: "Suspendidos",
                    amount: `${metricas.pagosManualesSuspendidos}`,
                  }}
                />
              </MDBox>
            </Grid>
            {/**este es el puito cuadrito de todays user*/}
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="credit_card"
                  title="Pagos Automáticos"
                  count={
                    parseInt(metricas.pagosAutomaticos) +
                      parseInt(metricas.pagosAutomaticosSuspendidos) || 0
                  }
                  userActive={{
                    color: "green",
                    label: "Procesados",
                    amount: `${metricas.pagosAutomaticos || 0}`,
                  }}
                  suspendidos={{
                    color: "error",
                    label: "Suspendidos",
                    amount: `${metricas.pagosAutomaticosSuspendidos}`,
                  }}
                />
              </MDBox>
            </Grid>
            {/**este es el puito cuadrito de Revenue*/}
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="account_balance_wallet"
                  title="Pagos Totales"
                  count={
                    parseInt(metricas.pagosManualesActivos) +
                      parseInt(metricas.pagosManualesInactivos) +
                      parseInt(metricas.pagosAutomaticos) +
                      parseInt(metricas.pagosAutomaticosSuspendidos) +
                      parseInt(metricas.pagosManualesSuspendidos) || 0
                  }
                  userActive={{
                    color: "green",
                    label: "Total de Pagos Procesados",
                    amount: `${
                      parseInt(metricas.pagosManualesActivos) +
                        parseInt(metricas.pagosAutomaticos) || 0
                    }`,
                  }}
                  userInactive={{
                    color: "error",
                    label: "Total de Pagos Por Procesar",
                    amount: `${parseInt(metricas.pagosManualesInactivos) || 0}`,
                  }}
                  suspendidos={{
                    color: "error",
                    label: "Suspendidos",
                    amount: `${
                      parseInt(metricas.pagosAutomaticosSuspendidos) +
                      parseInt(metricas.pagosManualesSuspendidos)
                    }`,
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}></Grid>
            <Grid item xs={12} md={6} lg={4}></Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
