import { useMemo, useState } from "react";
import { useMaterialUIController, fetchPayments } from "context";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import SpringModal from "examples/Modal";

// Data
import axios from "axios";
import Cookies from "js-cookie";
import manualPaymentsTableData from "layouts/table_manual_payments/data/manualPaymentsTableData";
import Loading from "components/Loading";

function TableManualPayments() {
  const [controller, dispatch] = useMaterialUIController();

  const { status } = controller;

  const [modalDelete, setModalDelete] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalProofPayment, setModalProofPayment] = useState(false);
  const [select, setSelect] = useState(1);
  const [tempSelect, setTempSelect] = useState(1);
  const [payment, setPayment] = useState(null);
  const [dataForm, setDataForm] = useState({
    name: "",
    lastName: "",
    phone: "",
    status: "",
    email: "",
  });
  const [loading, setLoading] = useState(false);

  const token = Cookies.get("access_token");

  const handleDeleteClick = (pay) => {
    if (pay?.payment_status !== "1") return;
    setPayment(pay);
    setModalDelete(true);
  };

  const handleEditClick = (pay) => {
    setPayment(pay);
    setModalEdit(true);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;

    setDataForm((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleModalClose = () => {
    setModalDelete(false);
    setModalEdit(false);
    setModalProofPayment(false);
  };

  const confirmDelete = async () => {
    try {
      const response = await axios.delete(`/admin/payments/${payment?.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      fetchPayments(dispatch);
      setModalDelete(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.put(
        `/admin/payments/${payment?.id}`,
        {
          invoice_number: payment?.invoice_number,
          // payment_proof: payment?.payment_proof,
          subscription_id: payment?.Subscriptions?.id,
          methodPayments_id: payment?.methodPayments?.id,
          planPayment_id: payment?.planPayments?.id,
          status_id: dataForm.status,
          trader_id: payment?.Traders?.id,
          amount: payment?.amount,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        fetchPayments(dispatch);
        handleModalClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleProofPayment = async (pay) => {
    try {
      setLoading(true);
      setModalProofPayment(true);
      const { data } = await axios.get(`/admin/payments/getPaymentProof/${pay}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (data.message === "Comprobante encontrado.") {
        setPayment(data.data);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChange = (e) => {
    setTempSelect(e.target.value);
  };

  const handleSelect = () => {
    setSelect(tempSelect);
    fetchPayments(dispatch);
  };

  const { columns, rows } = useMemo(
    () =>
      manualPaymentsTableData({
        handleDeleteClick,
        handleEditClick,
        handleProofPayment,
        setSelect,
        select,
      }),
    [handleDeleteClick, handleEditClick, handleProofPayment, setSelect, select]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Pago Manual
                </MDTypography>
              </MDBox>

              <MDBox display="flex" justifyContent="flex-start" mx={2} mt={0} pt={2} px={0}>
                <MDBox fullWidth mr={2}>
                  <FormControl fullWidth>
                    <InputLabel id="select-label">Sala</InputLabel>
                    <Select
                      labelId="select-label"
                      id="simple-select"
                      type="select"
                      name="sala"
                      value={tempSelect}
                      onChange={handleSelectChange}
                      placeholder="Sala"
                      label="Sala"
                      fullWidth
                      style={{ padding: ".75rem" }}
                    >
                      <MenuItem value={1}>SALA PRE ANÁLISIS</MenuItem>
                      <MenuItem value={2}>SALA POST ANÁLISIS</MenuItem>
                    </Select>
                  </FormControl>
                </MDBox>

                <MDButton color="info" size="small" onClick={handleSelect}>
                  Buscar
                </MDButton>
              </MDBox>

              <MDBox pt={0}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={{ defaultValue: 10, entries: [10, 15, 20, 25] }}
                  showTotalEntries={false}
                  noEndBorder
                  pagination={{ variant: "gradient", color: "info" }}
                  canSearch={true}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <SpringModal open={modalDelete} onClose={handleModalClose}>
        <MDTypography variant="h6" component="h2" textAlign={"center"}>
          ¿Estás seguro de que quieres deshabilitar al usuario ?
        </MDTypography>

        <MDBox display={"flex"} justifyContent={"center"} sx={{ mt: 4 }}>
          <MDButton variant="gradient" color="secondary" onClick={confirmDelete}>
            Confirmar
          </MDButton>

          <MDButton variant="gradient" color="primary" onClick={handleModalClose} sx={{ ml: 2 }}>
            Cancelar
          </MDButton>
        </MDBox>
      </SpringModal>

      <SpringModal open={modalEdit} onClose={handleModalClose}>
        <MDBox mb={2}>
          <FormControl fullWidth>
            <InputLabel id="select-label">Status</InputLabel>
            <Select
              labelId="select-label"
              id="simple-select"
              type="select"
              name="status"
              value={dataForm.status}
              onChange={handleFormChange}
              placeholder="Status"
              label="Status"
              fullWidth
              style={{ padding: ".75rem" }}
            >
              {status?.data?.map((status) => {
                if (status.id === 5 || status.id === 6 || status.id === 4) {
                  return (
                    <MenuItem key={status.id} value={status.id}>
                      {status.name}
                    </MenuItem>
                  );
                }
              })}
            </Select>
          </FormControl>
        </MDBox>

        <MDBox display={"flex"} justifyContent={"center"} sx={{ mt: 4 }}>
          <MDButton variant="gradient" color="secondary" onClick={handleSubmit}>
            Guardar
          </MDButton>

          <MDButton variant="gradient" color="primary" onClick={handleModalClose} sx={{ ml: 2 }}>
            Cancelar
          </MDButton>
        </MDBox>
      </SpringModal>

      <SpringModal open={modalProofPayment} onClose={handleModalClose} width={500}>
        {loading ? (
          <MDBox display="flex" justifyContent="center" alignItems="center" height="100%">
            <Loading />
          </MDBox>
        ) : (
          <img
            src={payment}
            width={"100%"}
            height={"100%"}
            style={{ objectFit: "contain" }}
            onLoad={() => {
              setLoading(false);
            }}
          />
        )}
      </SpringModal>
    </DashboardLayout>
  );
}

export default TableManualPayments;
