import React, { memo, useEffect, useState } from "react";
import { useMaterialUIController, fetchUsers } from "context";

import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import SpringModal from "examples/Modal";

import axios from "axios";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import MDSnackbar from "components/MDSnackbar";

import {
  validateName,
  validateLastName,
  validateCharacters,
  validatePhone,
  validatePhoneNumber,
} from "../../../utils/validations";

const UserEdit = ({ user, users, modalEdit, setModalEdit, token }) => {
  const user_id = Cookies.get("user_id");

  const [snackbar, setSnackbar] = useState({
    open: false,
    color: "",
    icon: "",
    title: "",
    content: "",
  });

  const [dataForm, setDataForm] = useState({
    name: user?.person?.name || "",
    lastName: user?.person?.lastname || "",
    phone: user?.person?.phone_number || "",
    status: user?.status?.id || "",
    email: user?.person?.email || "",
    rol: user?.rol?.id || "",
  });

  const [errors, setErrors] = useState({
    name: "",
    lastName: "",
    phone: "",
  });

  useEffect(() => {
    if (user) {
      setDataForm({
        id: user.id || "",
        name: user.person?.name || "",
        lastName: user.person?.lastname || "",
        phone: user.person?.phone_number || "",
        status: users.data.status.find((status) => status.name === user.status.name)?.id || "",
        email: user.person?.email || "",
        rol: user.rol.id || "",
      });
    }
  }, [user]);

  const [controller, dispatch] = useMaterialUIController();

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    let errorMessage = "";

    // Verificación del input name
    if (name === "name") {
      if (!validateCharacters(value)) {
        return;
      }

      const { error } = validateName(value);

      errorMessage = error;
    }

    // Verificación del input lastname
    if (name === "lastName") {
      if (!validateCharacters(value)) {
        return;
      }
      const { error } = validateLastName(value);
      errorMessage = error;
    }

    // Verificación del input phone
    if (name === "phone") {
      if (!validatePhoneNumber(value)) {
        return;
      }

      const { error } = validatePhone(value);
      errorMessage = error;
    }

    setDataForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Guarda los errores en la variable de estado "Errors"
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.put(
        `/admin/users/${user.id}`,
        {
          name: dataForm.name,
          lastname: dataForm.lastName,
          phone_number: dataForm.phone,
          status: dataForm.status,
          email: user?.email,
          rol_id: dataForm.rol,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        fetchUsers(dispatch);
        handleModalClose();

        //Muestra mensaje si se crea el usuario
        setSnackbar({
          open: true,
          icon: "check",
          color: "success",
          title: "Hecho!",
          content: "Usuario modificado con exito",
        });
      }
    } catch (error) {
      console.error(error);

      // Muestra un mensaje de error si el usuario no tiene permisos
      if (error.response.status === 403) {
        setSnackbar({
          open: true,
          icon: "warning",
          color: "error",
          title: "Lo siento!",
          content: "No estas autorizado para realizar esta acción!",
        });
      }
    }
  };

  const handleModalClose = () => {
    setModalEdit(false);
  };

  return (
    <>
      <SpringModal open={modalEdit} onClose={handleModalClose}>
        <MDBox mb={2}>
          <MDInput
            type="email"
            value={user?.username}
            placeholder="Usuario"
            label="Usuario"
            fullWidth
            disabled
          />
        </MDBox>

        <MDBox mb={2}>
          <MDInput
            type="email"
            name="email"
            value={user?.person?.email}
            placeholder="Correo Electrónico"
            label="Correo Electrónico"
            fullWidth
            disabled
          />
        </MDBox>

        <MDBox display="flex" gap={2} mb={2}>
          <MDBox display="grid">
            <MDInput
              label="Nombre"
              placeholder="Nombre"
              name="name"
              type="text"
              value={dataForm.name}
              onChange={handleFormChange}
              fullWidth
              error={!!errors.name}
            />
            {errors.name && <FormHelperText error>{errors.name}</FormHelperText>}
          </MDBox>

          <MDBox display="grid">
            <MDInput
              label="Apellido"
              placeholder="Apellido"
              type="text"
              name="lastName"
              value={dataForm.lastName}
              onChange={handleFormChange}
              fullWidth
              error={!!errors.lastName}
            />
            {errors.lastName && <FormHelperText error>{errors.lastName}</FormHelperText>}
          </MDBox>
        </MDBox>

        <MDBox display="grid" mb={2}>
          <MDInput
            label="Teléfono"
            placeholder="Teléfono"
            type="text"
            name="phone"
            value={dataForm.phone}
            onChange={handleFormChange}
            fullWidth
            error={!!errors.phone}
          />
          {errors.phone && <FormHelperText error>{errors.phone}</FormHelperText>}
        </MDBox>

        {user?.id !== parseInt(user_id) && (
          <MDBox mb={2}>
            <FormControl fullWidth>
              <InputLabel id="select-label">Status</InputLabel>
              <Select
                labelId="select-label"
                id="simple-select"
                type="select"
                name="status"
                value={dataForm.status}
                onChange={handleFormChange}
                placeholder="Status"
                label="Status"
                fullWidth
                style={{ padding: ".75rem" }}
              >
                {users?.data?.status.map((status) => {
                  if (status.name === "Activo" || status.name === "Inactivo") {
                    return (
                      <MenuItem key={status.id} value={status.id}>
                        {status.name}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
            </FormControl>
          </MDBox>
        )}

        {user?.id !== parseInt(user_id) && (
          <MDBox mb={2}>
            <FormControl fullWidth>
              <InputLabel id="select-label">Rol</InputLabel>
              <Select
                labelId="select-label"
                id="simple-select"
                type="select"
                name="rol"
                value={dataForm.rol}
                onChange={handleFormChange}
                placeholder="Rol"
                label="Rol"
                fullWidth
                style={{ padding: ".75rem" }}
              >
                {users?.data?.roles.map((rol) => {
                  return (
                    <MenuItem key={rol.id} value={rol.id}>
                      {rol.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </MDBox>
        )}

        <MDBox display={"flex"} justifyContent={"center"} sx={{ mt: 4 }}>
          <MDButton variant="outlined" color="secondary" onClick={handleSubmit}>
            Guardar
          </MDButton>

          <MDButton variant="outlined" color="primary" onClick={handleModalClose} sx={{ ml: 2 }}>
            Cancelar
          </MDButton>
        </MDBox>
      </SpringModal>

      <MDSnackbar
        color={snackbar.color}
        icon={snackbar.icon}
        title={snackbar.title}
        content={snackbar.content}
        open={snackbar.open}
        close={() => setSnackbar({ ...snackbar, open: false })}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </>
  );
};

UserEdit.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    username: PropTypes.string,
    email: PropTypes.string,
    person: PropTypes.shape({
      name: PropTypes.string,
      lastname: PropTypes.string,
      email: PropTypes.string,
      phone_number: PropTypes.string,
    }),
    status: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    }),
    rol: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  users: PropTypes.shape({
    data: PropTypes.shape({
      status: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
      roles: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    }),
  }),
  setUser: PropTypes.func.isRequired,
  setModalEdit: PropTypes.func.isRequired,
  modalEdit: PropTypes.func.isRequired,
  token: PropTypes.string,
};

export default UserEdit;
