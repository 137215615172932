import React, { useState } from "react";
import { useMaterialUIController, fetchUsers } from "context";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import SpringModal from "examples/Modal";

import axios from "axios";
import PropTypes from "prop-types";

import {
  validateUser,
  validateUserCharacters,
  validateName,
  validateLastName,
  validateCharacters,
  validatePhone,
  validatePhoneNumber,
  validateEmail,
  validatePassword,
} from "../../../utils/validations";

import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";

const CreateUser = ({ users, modalCreate, setModalCreate, token }) => {
  const [controller, dispatch] = useMaterialUIController();

  const [snackbar, setSnackbar] = useState({
    open: false,
    color: "",
    icon: "",
    title: "",
    content: "",
  });

  const [createUser, setCreateUser] = useState({
    username: "",
    password: "",
    name: "",
    lastname: "",
    email: "",
    phone: "",
    rol: "",
  });
  const [isTypingPassword, setIsTypingPassword] = useState(false);
  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });

  const [errors, setErrors] = useState({
    username: "",
    password: "",
    name: "",
    lastname: "",
    email: "",
    phone: "",
  });

  // Función que permite ingresar valores en los inputs y realiza las validaciones correspondientes
  const handleCreateChange = (e) => {
    const { name, value } = e.target;
    let errorMessage = "";

    // Verificación del input username
    if (name === "username") {
      if (!validateUserCharacters(value)) {
        return;
      }

      const { error } = validateUser(value);

      errorMessage = error;
    }

    // Verificación del input name
    if (name === "name") {
      if (!validateCharacters(value)) {
        return;
      }

      const { error } = validateName(value);

      errorMessage = error;
    }

    // Verificación del input lastname
    if (name === "lastname") {
      if (!validateCharacters(value)) {
        return;
      }
      const { error } = validateLastName(value);
      errorMessage = error;
    }

    // Verificación del input phone
    if (name === "phone") {
      if (!validatePhoneNumber(value)) {
        return;
      }

      const { error } = validatePhone(value);
      errorMessage = error;
    }

    // Verificación del input email
    if (name === "email") {
      const { error } = validateEmail(value);
      errorMessage = error;
    }

    if (name === "password") {
      setIsTypingPassword(true); // Activar el estado de escritura solo cuando el usuario interactúa con el campo
      const requirements = validatePassword(value);
      setPasswordRequirements(requirements);

      const allValid = Object.values(requirements).every(Boolean);
      errorMessage = allValid ? "" : "La contraseña no cumple con los requisitos.";

      if (allValid) {
        setIsTypingPassword(false); // Desactiva el estado de escritura si todos los requisitos se cumplen
      }
    }

    // Guarda los valores ingresados en cada input en la variable de estado "CreateUser"
    setCreateUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Guarda los errores en la variable de estado "Errors"
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };

  // Función para crear un usuario
  const handleCreateUser = async () => {
    try {
      // Fetch para crear un usuario
      const response = await axios.post(
        "/admin/users",
        {
          name: createUser.name,
          lastname: createUser.lastname,
          username: createUser.username,
          password: createUser.password,
          email: createUser.email,
          phone_number: createUser.phone,
          rol_id: createUser.rol,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // Respuesta si el usuario se crea exitosamente
      if (response.status === 200) {
        // Actualiza la lista de los usuarios
        fetchUsers(dispatch);

        // Función para cerrar el modal
        handleModalClose();

        //Muestra mensaje si se crea el usuario
        setSnackbar({
          open: true,
          icon: "check",
          color: "success",
          title: "Hecho!",
          content: "Usuario creado con exito!",
        });

        // Limpia los campos de los inputs
        setCreateUser({
          name: "",
          lastname: "",
          email: "",
          username: "",
          password: "",
          phone: "",
          rol: "",
        });
      }
    } catch (error) {
      console.error(error);
      // Muestra un mensaje de error si el usuario no tiene permisos
      if (error.response.status === 403) {
        setSnackbar({
          open: true,
          icon: "warning",
          color: "error",
          title: "Lo siento!",
          content: "No estas autorizado para realizar esta acción!",
        });
      }

      if (error.response.data.message === "El correo electrónico ya está en uso.") {
        setSnackbar({
          open: true,
          icon: "warning",
          color: "error",
          title: "Lo siento!",
          content: `${error.response.data.message}`,
        });
      }

      if (error.response.data.message === "El usuario ya existe") {
        setSnackbar({
          open: true,
          icon: "warning",
          color: "error",
          title: "Lo siento!",
          content: `${error.response.data.message}`,
        });
      }
    }
  };

  // Función para cerrar el modal del formulario para crear usuario
  const handleModalClose = () => {
    setModalCreate(false);
    setCreateUser({
      name: "",
      lastname: "",
      email: "",
      username: "",
      password: "",
      phone: "",
      rol: "",
    });
  };

  // Función para verificar si estan todos los campos de los inputs completos y si no hay errores
  const hasErrors = () => {
    const hasFieldErrors = Object.values(errors).some((error) => error !== "");

    const isFormComplete = Object.values(createUser).every((value) =>
      typeof value === "string" ? value.trim() !== "" : value !== ""
    );

    return hasFieldErrors || !isFormComplete;
  };

  return (
    <>
      <SpringModal open={modalCreate} onClose={handleModalClose}>
        <MDTypography mb={2}>Crear Usuario</MDTypography>

        <MDBox mb={2}>
          <MDInput
            size="small"
            type="text"
            name="username"
            value={createUser.username}
            onChange={handleCreateChange}
            placeholder="Usuario"
            label="Usuario"
            fullWidth
            error={!!errors.username}
            requiered
          />
          {errors.username && <FormHelperText error>{errors.username}</FormHelperText>}
        </MDBox>

        <MDBox mb={2}>
          <MDInput
            size="small"
            type="password"
            name="password"
            value={createUser.password}
            onChange={handleCreateChange}
            placeholder="Contraseña"
            label="Contraseña"
            fullWidth
            requiered
          />
          {errors.password && <FormHelperText error>{errors.password}</FormHelperText>}
          {isTypingPassword && !Object.values(passwordRequirements).every(Boolean) && (
            <List dense sx={{ paddingLeft: ".5rem" }}>
              <ListItem>
                <ListItemText
                  primary={
                    <MDTypography
                      variant="caption"
                      style={{
                        color: passwordRequirements.length ? "green" : "red",
                      }}
                    >
                      Mínimo 8 y máximo 16 caracteres
                    </MDTypography>
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={
                    <MDTypography
                      variant="caption"
                      style={{
                        color: passwordRequirements.uppercase ? "green" : "red",
                      }}
                    >
                      Al menos una letra mayúscula
                    </MDTypography>
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={
                    <MDTypography
                      variant="caption"
                      style={{
                        color: passwordRequirements.lowercase ? "green" : "red",
                      }}
                    >
                      Al menos una letra minúscula
                    </MDTypography>
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={
                    <MDTypography
                      variant="caption"
                      style={{
                        color: passwordRequirements.number ? "green" : "red",
                      }}
                    >
                      Al menos un número
                    </MDTypography>
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={
                    <MDTypography
                      variant="caption"
                      style={{
                        color: passwordRequirements.specialChar ? "green" : "red",
                      }}
                    >
                      Al menos un carácter especial
                    </MDTypography>
                  }
                />
              </ListItem>
            </List>
          )}
        </MDBox>

        <MDBox display="grid" mb={2}>
          <MDInput
            size="small"
            label="Nombre"
            placeholder="Nombre"
            name="name"
            type="text"
            value={createUser.name}
            onChange={handleCreateChange}
            fullWidth
            error={!!errors.name}
            requiered
          />
          {errors.name && <FormHelperText error>{errors.name}</FormHelperText>}
        </MDBox>

        <MDBox display="grid" mb={2}>
          <MDInput
            size="small"
            label="Apellido"
            placeholder="Apellido"
            type="text"
            name="lastname"
            value={createUser.lastname}
            onChange={handleCreateChange}
            fullWidth
            error={!!errors.lastname}
            requiered
          />
          {errors.lastname && <FormHelperText error>{errors.lastname}</FormHelperText>}
        </MDBox>

        <MDBox display="grid" mb={2}>
          <MDInput
            size="small"
            label="Correo Electrónico"
            placeholder="Correo Electrónico"
            type="email"
            name="email"
            value={createUser.email}
            onChange={handleCreateChange}
            fullWidth
            error={!!errors.email}
            requiered
          />
          {errors.email && <FormHelperText error>{errors.email}</FormHelperText>}
        </MDBox>

        <MDBox display="grid" mb={2}>
          <MDInput
            size="small"
            label="Teléfono"
            placeholder="Teléfono"
            type="phone"
            name="phone"
            value={createUser.phone}
            onChange={handleCreateChange}
            fullWidth
            error={!!errors.phone}
            requiered
          />
          {errors.phone && <FormHelperText error>{errors.phone}</FormHelperText>}
        </MDBox>

        <MDBox mb={2}>
          <FormControl fullWidth>
            <InputLabel id="select-label" size="small">
              Rol
            </InputLabel>
            <Select
              size="small"
              labelId="select-label"
              id="simple-select"
              type="select"
              name="rol"
              value={createUser.rol}
              onChange={handleCreateChange}
              placeholder="Rol"
              label="Rol"
              fullWidth
              style={{ padding: ".75rem", zIndex: 100003 }}
            >
              {users?.data?.roles?.map((rol) => {
                return (
                  <MenuItem key={rol.id} value={rol.id}>
                    {rol.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </MDBox>

        <MDBox display={"flex"} justifyContent={"center"} sx={{ mt: 4 }}>
          <MDButton
            variant="outlined"
            color="secondary"
            onClick={handleCreateUser}
            disabled={hasErrors()}
          >
            Guardar
          </MDButton>

          <MDButton variant="outlined" color="primary" onClick={handleModalClose} sx={{ ml: 2 }}>
            Cancelar
          </MDButton>
        </MDBox>
      </SpringModal>

      <MDSnackbar
        color={snackbar.color}
        icon={snackbar.icon}
        title={snackbar.title}
        content={snackbar.content}
        open={snackbar.open}
        close={() => setSnackbar({ ...snackbar, open: false })}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </>
  );
};

CreateUser.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    email: PropTypes.string,
    person: PropTypes.shape({
      name: PropTypes.string,
      lastname: PropTypes.string,
      email: PropTypes.string,
      phone_number: PropTypes.string,
    }),
    status: PropTypes.shape({
      name: PropTypes.string,
    }),
    rol: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
  users: PropTypes.shape({
    data: PropTypes.shape({
      status: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        })
      ),
      roles: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        })
      ),
    }),
  }),
  setModalCreate: PropTypes.func.isRequired,
  modalCreate: PropTypes.func.isRequired,
  token: PropTypes.string,
};

export default CreateUser;
