import Dashboard from "layouts/dashboard";
import TableUsers from "layouts/table_users";
import SignIn from "layouts/authentication/sign-in";

// import Icon from "@mui/material/Icon";
import exitt from "./assets/iconos/exit.png";

import Icon from "assets/iconos";
import TableConfig from "layouts/table_config";
import TableChannels from "layouts/table_channels";
import TableManualPayments from "layouts/table_manual_payments";
import TableSuscriptores from "layouts/table_suscriptores";
import TableHistorial from "layouts/table_historial";
import Cover from "layouts/authentication/reset-password/cover";
import { logout } from "utils/auth";
import TableAutomaticPayments from "layouts/table_automatic_payments";
import TableTraders from "layouts/table_traders";
import TablePlans from "layouts/table_plans";

const routes = [
  {
    type: "collapse",
    name: "INICIO",
    key: "dashboard",
    icon: <Icon name="home" width={25} height={25} fill="white" />,
    route: "/dashboard",
    component: <Dashboard />,
    protected: true,
  },
  // {
  //   type: "collapse",
  //   name: "CANALES",
  //   key: "canales",
  //   icon: <Icon name="channel" width={25} height={25} fill="white" />,
  //   route: "/canales",
  //   colors: "green",
  //   component: <TableChannels />,
  //   protected: true,
  // },
  {
    type: "collapse",
    name: "PAGO MANUAL",
    key: "pago-manual",
    icon: <Icon name="dollar" width={25} height={25} fill="white" />,
    route: "/pago-manual",
    colors: "green",
    component: <TableManualPayments />,
    protected: true,
  },
  {
    type: "collapse",
    name: "PAGO AUTOMÁTICO",
    key: "pago-automatico",
    icon: <Icon name="creditcard" width={25} height={25} fill="white" />,
    route: "/pago-automatico",
    component: <TableAutomaticPayments />,
    protected: true,
  },
  {
    type: "collapse",
    name: "TRADERS",
    key: "traders",
    icon: <Icon name="clients" width={25} height={25} fill="white" />,
    route: "/traders",
    component: <TableTraders />,
    protected: true,
  },
  {
    type: "collapse",
    name: "SUSCRIPCIONES",
    key: "suscripciones",
    icon: <Icon name="bell" width={25} height={25} fill="white" />,
    route: "/suscripciones",
    component: <TableSuscriptores />,
    protected: true,
  },
  {
    type: "collapse",
    name: "PLANES",
    key: "planes",
    icon: <Icon name="article" width={25} height={25} fill="white" />,
    route: "/planes",
    component: <TablePlans />,
    protected: true,
  },
  {
    type: "collapse",
    name: "USUARIOS",
    key: "usuarios",
    icon: <Icon name="users" width={25} height={25} fill="white" />,
    route: "/usuarios",
    component: <TableUsers />,
    protected: true,
  },

  // {
  //   type: "collapse",
  //   name: "CONFIGURACIÓN",
  //   key: "configuraciones",
  //   icon: <Icon name="gear" width={25} height={25} fill="white" />,
  //   route: "/configuraciones",
  //   component: <TableConfig />,
  //   protected: true,
  // },
  {
    type: "collapse",
    name: "HISTORIAL",
    key: "historial",
    icon: <Icon name="historial" width={25} height={25} fill="white" />,
    route: "/historial",
    component: <TableHistorial />,
    protected: true,
  },

  {
    type: "collapse",
    name: "CERRAR SESIÓN",
    key: "sign-in",
    icon: <img src={exitt} alt="Custom Icon" style={{ width: 25, height: 20 }} />,
    route: "",
    component: "",
    onClick: logout,
  },
];

const authRoutes = [
  {
    key: "sign-in",
    route: "/authentication/sign-in",
    component: <SignIn />,
    protected: false,
  },
  {
    key: "reset-password",
    route: "/authentication/reset-password",
    component: <Cover />,
    protected: false,
  },
];

export { routes, authRoutes };
