// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import SpringModal from "examples/Modal";

// Data
import plansTableData from "layouts/table_plans/data/plansTableData";
import { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useMaterialUIController, fetchPlans } from "context";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

function TablePlans() {
  const [controller, dispatch] = useMaterialUIController();
  const rol = Cookies.get("user_p");
  const { plans, subsriptions } = controller;

  const [modalDelete, setModalDelete] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [plan, setPlan] = useState(null);
  const [dataForm, setDataForm] = useState({
    id: "",
    name: "",
    months: "",
    amount: "",
    status: "",
  });

  const token = Cookies.get("access_token");

  const handleDeleteClick = (s) => {
    if (s?.status.name !== "Activo") return;
    setPlan(s);
    setModalDelete(true);
  };

  const handleEditClick = (value) => {
    setPlan(value);

    const selectedStatus = plans?.data?.status.find(
      (status) => status.name === value?.status?.name
    );

    setDataForm({
      id: value?.id,
      name: value?.name,
      months: value?.month_quantity,
      amount: value?.amount,
      status: value?.status?.id,
    });

    setModalEdit(true);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;

    setDataForm((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleModalClose = () => {
    setModalDelete(false);
    setModalEdit(false);
  };

  const confirmDelete = async () => {
    try {
      const response = await axios.delete(`/admin/planPayments/${plan?.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      fetchPlans(dispatch);
      setModalDelete(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.put(
        `/admin/planPayments/${plan?.id}`,
        {
          name: dataForm.name,
          month_quantity: dataForm.months,
          amount: dataForm.amount,
          description: plan?.description,
          status_id: dataForm.status,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        fetchPlans(dispatch);
        handleModalClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const { columns, rows } = plansTableData({ handleDeleteClick, handleEditClick });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Planes
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {rol === "1" ? (
                  <MDBox fullWidth display="flex" justifyContent="center">
                    <MDTypography py={3}>No tienes permiso para ver este modulo</MDTypography>
                  </MDBox>
                ) : (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={{ defaultValue: 10, entries: [10, 15, 20, 25] }}
                    showTotalEntries={false}
                    noEndBorder
                    pagination={{ variant: "gradient", color: "info" }}
                    canSearch={true}
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <SpringModal open={modalDelete} onClose={handleModalClose}>
        <MDTypography variant="h6" component="h2" textAlign={"center"}>
          ¿Estás seguro de que quieres deshabilitar al usuario {plan?.username}?
        </MDTypography>

        <MDBox display={"flex"} justifyContent={"center"} sx={{ mt: 4 }}>
          <MDButton variant="gradient" color="secondary" onClick={confirmDelete}>
            Confirmar
          </MDButton>

          <MDButton variant="gradient" color="primary" onClick={handleModalClose} sx={{ ml: 2 }}>
            Cancelar
          </MDButton>
        </MDBox>
      </SpringModal>

      <SpringModal open={modalEdit} onClose={handleModalClose}>
        <MDBox mb={2}>
          <MDInput
            label="Nombre"
            placeholder="Nombre"
            type="text"
            name="name"
            value={dataForm?.name}
            onChange={handleFormChange}
            fullWidth
            requiered
          />
        </MDBox>

        <MDBox mb={2}>
          <MDInput
            type="text"
            name="months"
            value={dataForm?.months}
            onChange={handleFormChange}
            placeholder="Duracion del Plan (en meses)"
            label="Duracion del Plan (en meses)"
            fullWidth
          />
        </MDBox>

        <MDBox mb={2}>
          <MDInput
            type="text"
            name="amount"
            value={dataForm?.amount}
            onChange={handleFormChange}
            placeholder="Monto"
            label="Monto"
            fullWidth
            disabled={plan?.id === 1}
          />
        </MDBox>

        <MDBox mb={2}>
          <FormControl fullWidth>
            <InputLabel id="select-label">Status</InputLabel>
            <Select
              labelId="select-label"
              id="simple-select"
              type="select"
              name="status"
              value={dataForm.status}
              onChange={handleFormChange}
              placeholder="Status"
              label="Status"
              fullWidth
              style={{ padding: ".75rem" }}
            >
              {plans?.data?.status.map((status) => {
                if (status.id < 3) {
                  return (
                    <MenuItem key={status.id} value={status.id}>
                      {status.name}
                    </MenuItem>
                  );
                }
              })}
            </Select>
          </FormControl>
        </MDBox>

        <MDBox display={"flex"} justifyContent={"center"} sx={{ mt: 4 }}>
          <MDButton variant="gradient" color="secondary" onClick={handleSubmit}>
            Guardar
          </MDButton>

          <MDButton variant="gradient" color="primary" onClick={handleModalClose} sx={{ ml: 2 }}>
            Cancelar
          </MDButton>
        </MDBox>
      </SpringModal>
    </DashboardLayout>
  );
}

export default TablePlans;
