/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";

import { useMaterialUIController, fetchPayments } from "context";
import { useEffect, useState } from "react";
import { IconButton, Tooltip, Zoom } from "@mui/material";
import { Edit, PersonOff } from "@mui/icons-material";

export default function data({ handleEditClick, select }) {
  const [controller, dispatch] = useMaterialUIController();
  const { payments } = controller;
  const [rows, setRows] = useState([]);

  useEffect(() => {
    fetchPayments(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (payments) {
      const filterPayments = payments?.data?.payments.filter((p) => {
        if (select === 1) {
          return p?.Subscriptions?.id === 1;
        } else if (select === 2) {
          return p?.Subscriptions?.id === 2;
        } else {
          return true;
        }
      });

      const formattedRows = filterPayments
        ?.filter((p) => parseInt(p?.methodPayments?.is_manual) === 0)
        .sort((a, b) => b.id - a.id)
        .map((p) => ({
          id: p?.id,
          user: p?.Traders?.username,
          payment_date: p?.created_at,
          payment_reference: p?.methodPayments?.name,
          invoicenumber: p?.invoice_number,
          payment_proof: p?.id,
          payment_status: p?.status?.name,
          plan_payment: p?.planPayments?.name,
          totalPrice: parseInt(p?.amount),
          actions: p,
        }));
      setRows(formattedRows);
    }
  }, [payments, select]);

  const columns = [
    {
      Header: "id",
      accessor: "id",
      align: "left",
      width: "10%",
      Cell: ({ value }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {value}
          </MDTypography>
        </MDBox>
      ),
    },
    {
      Header: "usuario",
      accessor: "user",
      align: "left",
      Cell: ({ value }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <MDTypography variant="caption">{value}</MDTypography>
        </MDBox>
      ),
    },
    {
      Header: "Nº de Comprobante",
      accessor: "invoicenumber",
      align: "center",
      Cell: ({ value }) => (
        <MDBox ml={-1}>
          <MDTypography variant="caption">{value}</MDTypography>
        </MDBox>
      ),
    },
    {
      Header: "metodo de pago",
      accessor: "payment_reference",
      align: "left",
      Cell: ({ value }) => {
        return (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDBox lineHeight={1}>
              <MDTypography variant="caption">{value}</MDTypography>
            </MDBox>
          </MDBox>
        );
      },
    },

    {
      Header: "plan de pago",
      accessor: "plan_payment",
      align: "left",
      Cell: ({ value }) => {
        return (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDBox lineHeight={1}>
              <MDTypography variant="caption">{value}</MDTypography>
            </MDBox>
          </MDBox>
        );
      },
    },
    {
      Header: "Total",
      accessor: "totalPrice",
      align: "left",
      Cell: ({ value }) => {
        return (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDBox lineHeight={1}>
              <MDTypography variant="caption">${value}</MDTypography>
            </MDBox>
          </MDBox>
        );
      },
    },
    {
      Header: "Fecha del Pago",
      accessor: "payment_date",
      align: "center",
      Cell: ({ value }) => {
        let date = new Date(value);
        return (
          <MDBox ml={-1}>
            <MDTypography variant="caption">{date.toLocaleString()}</MDTypography>
          </MDBox>
        );
      },
    },

    // {
    //   Header: "comprobante",
    //   accessor: "payment_proof",
    //   align: "center",
    //   Cell: ({ value }) => (
    //     <MDBox ml={-1}>
    //       <MDTypography
    //         mx={0.5}
    //         component="a"
    //         href="#"
    //         variant="caption"
    //         color="text"
    //         fontWeight="medium"
    //         style={{
    //           cursor: "pointer",
    //           transition: "all 0.3s ease",
    //         }}
    //         onClick={() => handleProofPayment(value)}
    //       >
    //         Ver Comprobante
    //       </MDTypography>
    //     </MDBox>
    //   ),
    // },
    {
      Header: "Status",
      accessor: "payment_status",
      align: "center",
      Cell: ({ value }) => (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent={value}
            color={
              value === "Procesado" ? "success" : value === "Por Procesar" ? "warning" : "error"
            }
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
    },
    {
      Header: "acciones",
      accessor: "actions",
      align: "center",
      Cell: ({ value: user }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <Tooltip
            title="Editar"
            placement="top"
            TransitionComponent={Zoom}
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -10],
                    },
                  },
                ],
              },
            }}
          >
            <IconButton size="small" onClick={() => handleEditClick(user)}>
              <Edit />
            </IconButton>
          </Tooltip>
        </MDBox>
      ),
    },
  ];

  return { columns, rows };
}
