/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";

import { useMaterialUIController, fetchPlans } from "context";
import { useEffect, useState } from "react";
import { IconButton, Tooltip, Zoom } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { fetchSubscriptions } from "context";

export default function data({ handleDeleteClick, handleEditClick, handleProofPayment }) {
  const [controller, dispatch] = useMaterialUIController();

  const { plans, subscriptions } = controller;

  const [rows, setRows] = useState([]);

  useEffect(() => {
    fetchPlans(dispatch);
    fetchSubscriptions(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (plans) {
      const formattedRows = plans?.data?.planPayments
        .filter((p) => parseInt(p?.amount) !== 0)
        .map((plan) => ({
          id: plan?.id,
          name: plan?.name,
          months: plan?.month_quantity,
          amount: plan?.amount,
          status: plan?.status?.name,
          actions: plan,
        }));

      setRows(formattedRows);
    }
  }, [plans]);

  const columns = [
    {
      Header: "id",
      accessor: "id",
      align: "left",
      width: "10%",
      Cell: ({ value }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {value}
          </MDTypography>
        </MDBox>
      ),
    },
    {
      Header: "nombre",
      accessor: "name",
      align: "left",
      width: "10%",
      Cell: ({ value }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {value}
          </MDTypography>
        </MDBox>
      ),
    },
    {
      Header: "Meses",
      accessor: "months",
      align: "left",
      Cell: ({ value }) => {
        return (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDBox lineHeight={1}>
              <MDTypography variant="caption">{value}</MDTypography>
            </MDBox>
          </MDBox>
        );
      },
    },
    {
      Header: "Monto",
      accessor: "amount",
      align: "left",
      Cell: ({ value }) => {
        return (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDBox lineHeight={1}>
              <MDTypography variant="caption">$ {value}</MDTypography>
            </MDBox>
          </MDBox>
        );
      },
    },
    {
      Header: "status",
      accessor: "status",
      align: "center",
      Cell: ({ value }) => (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent={value}
            color={value === "Activo" ? "success" : value === "Inactivo" ? "warning" : "error"}
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
    },
    {
      Header: "acciones",
      accessor: "actions",
      align: "center",
      Cell: ({ value: subs }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <Tooltip
            title="Editar"
            placement="top"
            TransitionComponent={Zoom}
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -10],
                    },
                  },
                ],
              },
            }}
          >
            <IconButton size="small" onClick={() => handleEditClick(subs)}>
              <Edit />
            </IconButton>
          </Tooltip>
        </MDBox>
      ),
    },
  ];

  return { columns, rows };
}
