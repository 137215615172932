import React from "react";
import PropTypes from "prop-types";

import { ReactComponent as HomeIcon } from "./apps-icon.svg";
import { ReactComponent as UsersIcon } from "./users-icon.svg";
import { ReactComponent as ClientsIcon } from "./group-icon.svg";
import { ReactComponent as Historial } from "./recent-icon.svg";
import { ReactComponent as GearIcon } from "./gear-icon.svg";
import { ReactComponent as DollarIcon } from "./dollar-icon.svg";
import { ReactComponent as CreditCard } from "./credit-card-icon.svg";
import { ReactComponent as PencilIcon } from "./pencil-icon.svg";
import { ReactComponent as TrashIcon } from "./trash-icon.svg";
import { ReactComponent as UserX } from "./user-x-icon.svg";
import { ReactComponent as Channel } from "./channel.svg";
import { ReactComponent as ArrowRightLeft } from "./arrow-right-left.svg";
import { ReactComponent as Bell } from "./bell-icon.svg";
import { ReactComponent as Article } from "./article-icon.svg";

const icons = {
  home: HomeIcon,
  users: UsersIcon,
  clients: ClientsIcon,
  historial: Historial,
  gear: GearIcon,
  dollar: DollarIcon,
  creditcard: CreditCard,
  pencil: PencilIcon,
  trash: TrashIcon,
  userx: UserX,
  channel: Channel,
  arrowRightLeft: ArrowRightLeft,
  bell: Bell,
  article: Article,
};

const Icon = ({ name, ...props }) => {
  const Component = icons[name];
  return Component ? <Component {...props} /> : null;
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Icon;
